import React, { useState } from "react";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import Modal from "./components/Modal";
import "./App.css";

const BackableLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 140"
    className="logo"
  >
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: "#00BFFF", stopOpacity: 1 }} />
        <stop offset="50%" style={{ stopColor: "#BA55D3", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#7FFF00", stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <text
      x="200"
      y="80"
      fontFamily="Arial"
      fontSize="60"
      fontWeight="bold"
      fill="url(#grad1)"
      textAnchor="middle"
    >
      Backable
    </text>
    <text
      x="200"
      y="120"
      fontFamily="Arial"
      fontSize="24"
      fill="#FFFFFF"
      textAnchor="middle"
    >
      Performance. Unlocked.
    </text>
  </svg>
);

function App() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    country: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/register_new_user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            business_name: formData.businessName,
            country: formData.country,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (
          response.status === 400 &&
          errorData.message ===
            "User already exists in the queue. Please delete the user first"
        ) {
          setModalMessage(
            "You have already registered your interest. If you need to make changes, please contact us."
          );
        } else {
          throw new Error(errorData.message || "Network response was not ok");
        }
      } else {
        setModalMessage(
          "Thank you very much for contacting Backable. We are excited to be coming to life in 2024. You have been added to the waitlist and we will reach out soon to get you going on a new era of high performance in small to medium-sized businesses."
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          businessName: "",
          country: "",
        });
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setModalMessage(
        "There was a problem with your submission. Please try again later."
      );
    } finally {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="container">
      <div className="content-wrapper">
        <BackableLogo className="logo" />
        <h1>Coming 2024</h1>
        <p>Get ready to transform your business with AI-powered insights.</p>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <Input
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="input"
            />
            <Input
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="input"
            />
            <Input
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
              className="input"
            />
            <Input
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              placeholder="Business Name"
              required
              className="input"
            />
            <Input
              name="country"
              value={formData.country}
              onChange={handleChange}
              placeholder="Country"
              required
              className="input"
            />
            <Button type="submit" className="button">
              Join the Queue
            </Button>
          </div>
        </form>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="modal-title">Thank you for your interest!</h2>
        <p className="modal-description">{modalMessage}</p>
      </Modal>
    </div>
  );
}

export default App;
